<!--
 * @Date: 2021-12-06 15:08:55
 * @LastEditors: wfj
 * @LastEditTime: 2023-05
 * @FilePath: /dc-pay-front/src/views/Home/Banners.vue
-->
<template>
  <div class="container">
    <!-- 不使用vant的swiper的原因：pc下无法正常手动切换banner -->
    <!-- 相关文档
        swiper文档：https://www.swiper.com.cn/api/start/new.html
        vue-awesome-swiper文档：https://github.com/surmon-china/vue-awesome-swiper/tree/v4.1.1
     -->
    <swiper class="mySwiper" :options="swiperOptions" ref="mySwiper">
      <swiper-slide v-for="(item, index) in banners" :key="index">
        <van-image
          class="swiper-item"
          :src="item.image_url"
          @click="onclickDetailBtn(item)"
        />
      </swiper-slide>
      <div
        v-show="banners.length > 1"
        class="swiper-pagination banner-pagination__dot"
        slot="pagination"
      ></div>
    </swiper>

    <!-- 默认不加载活动信息弹窗，因为活动信息翻译可能不存在（控制台出现很多警告） -->
    <!-- <ActivityDetailPopup
      ref="detailPopup"
      v-if="isLoadDetailPop"
    ></ActivityDetailPopup> -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  getVersionByParseUrl,
  getVersionDetailInfo
} from '@/utils/business.js'
import { openLinkOnNewWindow } from '@/utils/'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  inject: ['getData'],
  directives: {
    swiper: directive
  },
  props: {},
  data () {
    return {
      swiperOptions: {
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination'
        }
      },
      disableAllBannerClick: false, // 是否禁用所有banner的点击功能
      // isLoadDetailPop: false, // 是否加载活动详情弹窗
      banners: [],
      lang: '',
      versionName: ''
    }
  },
  computed: {},
  methods: {
    async getBanners () {
      let { region: version } = getVersionDetailInfo()
      version = version ? version.join('_') : ''
      const { click_event_disable, ad_infos } = await Apis.getBanners({
        version
      })
      this.disableAllBannerClick = click_event_disable || false
      if (ad_infos && ad_infos.length) {
        this.banners = ad_infos
      }
    },
    onclickDetailBtn (item) {
      const { click_event_disable, link_url } = item
      if (this.disableAllBannerClick || click_event_disable) return
      // 活动结束，暂不显示弹窗
      this.$emit('clickBanner', item)
      link_url && openLinkOnNewWindow(link_url)

      // 暂时先手动控制弹窗显示，避免后台开启 前端文案未处理 而展示错误的i18n翻译
      // this.openDetailPopup()
    },
    // openDetailPopup () {
    //   if (this.isLoadDetailPop) {
    //     this.$refs.detailPopup.showPopup()
    //   } else {
    //     this.isLoadDetailPop = true
    //     this.$nextTick(() => {
    //       this.$refs.detailPopup.showPopup()
    //     })
    //   }
    // },
    onSwiper (swiper) {},
    onSlideChange () {}
  },
  mounted () {},
  created () {
    const { lang } = this.getData()
    const { versionName } = getVersionByParseUrl()
    this.versionName = versionName
    this.lang = lang

    this.getBanners()
  }
}
</script>

<style scoped lang="less">
@import url("~@/styles/mixin.less");

.container {
  margin: var(--dp-sp-lg);
  overflow: hidden;
  border-radius: var(--dp-b-rd-md);
}
.mySwiper {
  width: 100%;
  overflow: hidden;
  height: 388px;
  position: relative;
}
.swiper-item {
  width: 100%;
  height: 388px;
  border-radius: var(--dp-b-rd-md);
  overflow: hidden;
}
.banner-pagination__dot {
  :deep(.swiper-pagination-bullet) {
    width: 12px;
    height: 12px;
    margin: 0 10px;
  }
  :deep(.swiper-pagination-bullet-active) {
    background-color: var(--dp-tertiary);
  }
}
</style>
